.app_users_content {
  margin-top: 0px;
  .search {
    padding-bottom: 25px;
  }
  .filters {
    /*
    left: -60px;
    width: 550px;
    background-color: white;
    */
    p {
      font-size: 0.9em;
    }
  }

  .user-pic {
    width: 90px;
    height: 90px;
    img {
      max-width: 90px;
      max-height: 90px;
      margin: auto;
      border-radius: 45px;
    }
    i.fas {
      font-size: 4em;
    }
  }

  // User popup
  #show_modal {
    .modal-dialog {
      overflow-y: initial !important;
      max-width: 100%;
      width: 95%;
      height: 90%;
      padding: 0;
    }

    .modal-content {
      height: 90%;
      border-radius: 0;

      .modal-header {
        .modal-title {
          position: absolute;
        }
      }

      .modal-body {
        height: 250px;
        overflow-y: auto;
        .picture {
          align-items: center;
          img {
            margin-left: 15px;
            border-radius: 50%;
            width: 125px;
          }
        }
        .table_title_h4 {
          padding-left: 50px;
        }
        .submissions_details,
        .submissions_list {
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          caption {
            caption-side: top;
            margin-bottom: 5px;
          }
          table {
            text-align: center;
            border-collapse: collapse;
          }
          table,
          th,
          td {
            border: 1px solid black;
          }
          th,
          td {
            padding: 5px;
          }
        }

        .details {
          margin-left: 25px;
        }

        .reg_info {
          margin-right: 25px;
        }
      } // END OF MODAL BODY
    } // END OF MODAL CONTENT
  } //END OF SHOW_MODAL
}
