.generic-listing {
  @extend .row;
  @extend .mb-4;

  p {
    margin-bottom: 0;
  }

  .listing-info {
    @extend .col;
    .title {
      font-size: 24px;
      font-weight: $regular-bold-weight;
      color: $juvo-blue;
      display: block;
      text-decoration: none;
      margin-bottom: $paragraph-margin-bottom;
    }

    .location {
      font-size: $regular-medium-font-size;
      color: $juvo-dark-gray;
      margin-bottom: 15px;
    }

    .description {
      color: $juvo-dark-gray;

      ul {
        display: flex;
        justify-content: space-between;
        i {
          color: $juvo-blue;
          margin-right: 0.25rem;
        }
      }
    }
  }

  .job-steps {
    @extend .col;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    .step {
      display: block;
      text-decoration: none;
      min-width: 8em;

      .step-count {
        font-weight: 800;
        font-size: 30px;
        color: $juvo-blue;
      }

      .step-title {
        font-size: 12px;
        color: $juvo-light-gray;
        line-height: 22px;
        p {
          white-space: nowrap;
        }
      }
    }
  }
}

.jobs-index {
  .listing-info {
    .description {
      ul {
        display: block;
      }
    }
  }
}
