.logged-in-user-link {
  color: white;
  a {
    color: white;
    padding: $nav-link-padding-x;
    margin-right: $navbar-nav-link-padding-x;
  }
}

.page-footer {
  margin-top: 20px;
}

.login-container {
  h2 {
    font-family: $regular-font;
    font-weight: normal;
    font-size: 27px;
    color: #37394c;
    letter-spacing: 0;
    text-align: center;
  }

  .field {
    font-size: 13px;
    color: #97a3b4;
    line-height: 22px;
  }

  input {
    min-width: 320px;
  }
}

.paginator {
  background: white;
  cursor: default;
  margin-right: 1rem;
}
.paginator a,
.paginator span,
.paginator em {
  padding: 0.2em 0.5em;
  display: block;
  float: left;
  margin-right: 1px;
  border-radius: $btn-border-radius;
}
.paginator .disabled {
  color: #999999;
  border: 1px solid #dddddd;
}
.paginator .current {
  font-style: normal;
  font-weight: bold;
  background: $juvo-blue;
  color: white;
  border: 1px solid $juvo-blue;
}
.paginator a {
  text-decoration: none;
  color: #105cb6;
  border: 1px solid #9aafe5;
}
.paginator a:hover,
.paginator a:focus {
  color: #000033;
  border-color: #000033;
}
.paginator .page_info {
  background: $juvo-blue;
  color: white;
  padding: 0.4em 0.6em;
  width: 22em;
  margin-bottom: 0.3em;
  text-align: center;
}
.paginator .page_info b {
  color: #000033;
  background: #6aa6ed;
  padding: 0.1em 0.25em;
}
.paginator:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.pagination {
  .current {
    font-weight: bold;
    margin-left: 0.25em;
  }
  a {
    margin-left: 0.25em;
  }
}
* html .paginator {
  height: 1%;
}
*:first-child + html .paginator {
  overflow: hidden;
}
.pagination-container {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.paginator-page-size {
  display: flex;
  flex-direction: row;
  align-items: center;
  select {
    width: auto !important;
  }
  label {
    margin-left: 0.5rem;
  }
}
.account-admin-side-nav {
  background-color: $juvo-dark-blue;
  height: 100%;
  color: $juvo-almost-white;
}
.account-admin-nav {
  .nav-link {
    color: $juvo-almost-white;
    i {
      width: 2rem;
      text-align: center;
    }
  }
  .nav-link.active {
    font-weight: bold;
  }
  .nav-link.active::after {
    font-size: smaller;
    font-family: "Font Awesome 6 Free";
    content: "\f054";
    font-weight: 900;
    margin-left: 1rem;
  }
}