.job-submission-detail {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  .user-profile {
    flex-basis: 50%;
    padding-right: 0.5rem;
    .info {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      .avatar {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        img {
          height: 100px;
          width: 100px;
          border-radius: 20px;
          object-fit: cover;
        }
        i.fa-user {
          height: 100px;
          width: 100px;
          font-size: 100px;
        }
        margin-right: 10px;
        .play-video-button {
          text-decoration: none;
        }
      }
      .contact-info {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
    .video {
      video {
        max-width: 320px;
      }
    }
  }
  .contact-container {
    flex-basis: 50%;
    padding-left: 0.5rem;
  }
}

.job-submission-detail-dialog {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  height: 100%;
  .user-profile {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .contact-container {
    position: relative;
    height: 100%;
  }
  .video {
    video {
      max-width: 300px;
    }
  }
  .chat {
  }
}

.conversation-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  h6 {
    flex-grow: 1;
    flex-shrink: 1;
  }
}


.chat-message {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  position: relative;
  color: white;
  background-color: #0b93f6;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  .time {
    line-height: normal;
    font-size: x-small;
    font-style: italic;
  }
  .text {
    line-height: normal;
    font-size: small;
  }
}

.chat-message-left {
  margin-left: 30px;
  margin-right: auto;
  background-color: $juvo-dark-gray;
}
.chat-message-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: $juvo-dark-gray transparent transparent transparent;
}

.chat-message-right {
  margin-left: auto;
  margin-right: 30px;
}
.chat-message-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: #0b93f6 transparent transparent transparent;
}

.chat-message-date-separator {
  text-align: center;
  font-size: small;
  font-weight: bold;
  color: $juvo-dark-gray;
}

.chat-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  form {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;
    textarea {
      flex-grow: 1;
      flex-shrink: 1;
    }
    input[type="submit"] {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 1rem;
    }
  }
}

.avatar-video-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 60px;
  padding: 20px;
  color: #bbbbbbbb;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  background-color: darkgray;
}

.close-video-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;
  font-size: 40px;
  color: white;
  text-decoration: none;
}

.video {
  width: 100%;
  height: 100%;
  background-color: #00000044;
}

table.seeker-chats-table {
  tr[data-viewed="true"] {
    td.status::before {
      content: "\f058";
      font-family: "Font Awesome 5 Pro";
    }
  }
  tr[data-replied="true"] {
    td.status::before {
      content: "\f058";
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      color: darkgreen;
    }
  }
}

table.submissions-table {
  tr[data-read="true"] {
    td.status::before {
      content: "\f058";
      font-family: "Font Awesome 5 Pro";
    }
  }
}

.conversation-link {
  font-size: larger;
}

.chat-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  .chat-header {
    padding: $card-cap-padding-y $card-cap-padding-x;
    margin-bottom: 0; // Removes the default margin-bottom of <hN>
    color: $card-cap-color;
    border-bottom: $card-border-width solid $card-border-color;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  
    &:first-child {
      border-radius: $card-inner-border-radius $card-inner-border-radius 0 0;
    }
  
  }
  .chat-footer {
    padding: $card-cap-padding-y $card-cap-padding-x;
    color: $card-cap-color;
    border-top: $card-border-width solid $card-border-color;
  
    &:last-child {
      border-radius: $card-inner-border-radius $card-inner-border-radius;
    }
  }
  .chat-messages {
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
    flex: 1 1 auto;
    overflow-y: scroll;
    width: 100%;
  
    .blocker {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.65);
      color: white;
      text-align: center;
      max-width: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &.blocked {
      .blocker {
        display: flex;
      }
    }
  
    .no-chat {
      position: relative;
      text-align: center;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    div {
      max-width: 255px;
      word-wrap: break-word;
      margin-bottom: 12px;
      line-height: 24px;
  
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
    p {
      @include p-fix;
    }
  }
    
}

