// Place all the styles related to the locations controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.dashboard-locations{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
  .location{
    flex:21%;
    max-width:357px;
    text-decoration:none;
    h3{
      font-weight:$regular-semibold-weight;
      font-size: 18px;
      color:#4A4A4A;
    }
    p{
      font-size:13px;
      color:#4A4A4A;
      min-height:50px;
    }
    .location-detail{
      padding: 30px;
      .jobs-container{
        display:flex;
        margin:auto;
        justify-content: space-around;
        .info-container{
          min-width:100px;
          text-align:center;
          h1{
            color:$juvo-blue;
            font-size:30px;
            font-weight:$regular-extrabold-weight;
          }
          p{
            font-weight:$regular-semibold-weight;
            font-size: 12px;
            color:$juvo-light-gray;
            text-align: center;
          }
        }
      }
    }

    &.add-location{
      
      .location-detail{
        border: 1px solid $juvo-border-color;
        min-height:420px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        i{
          font-size:34px;
          color: $juvo-blue;
        }
      }
    }
  }
}

.location-address-map {
  width: 100%;
  height: 100%;
}

/* required if places google.maps.places.AutoComplete is in a bootstrap modal */
.pac-container {
  z-index: 1100 !important;
}