// Place all the styles related to the jobs controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.jobs-index{
  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top:24px;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .form-group {
      display: flex;
      align-items: center;
    }

    label {
      margin-right: 12px;
      margin-bottom: 0px;
    }

    
  }
}