// Place all the styles related to the dashboard/account/users controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.user-contact-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  .avatar {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    img {
      height: 100px;
      width: 100px;
      border-radius: 20px;
      object-fit: cover;
    }
    i.fa-user {
      height: 100px;
      width: 100px;
      font-size: 100px;
    }
    margin-right: 10px;
    .play-video-button {
      text-decoration: none;
    }
  }
}