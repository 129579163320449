.reports-index {
  caption {
    caption-side: top;
    margin-bottom: 5px;
  }
  table {
    text-align: center;
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid black;
  }
  th, td {
    padding: 1px;
  }
  .report-details{
    table {
      width: 100%;
    }
  }

  .report-revenue{
    table {
      width: 100px;
    }
  }
}

.reports-results {
  caption {
    caption-side: top;
    margin-bottom: 5px;
  }
  table {
    text-align: center;
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid black;
  }
  th, td {
    padding: 1px;
  }
  .report-details{
    table {
      width: 100%;
    }
  }

  .report-revenue{
    table {
      width: 100px;
    }
  }
}

.reports-table {
  th, td {
    font-size: 9pt;
    font-family: 'Verdana';
    line-height: 1.2em;
  }
}
