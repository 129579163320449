body {
  font-family: $regular-font;
  line-height: 1.5em;
  height: 100vh;
}
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  flex: 0 0 auto;
}
.footer {
  flex: 0 0 auto;
}
.main-content {
  flex: 1 1 auto;
  overflow-y: auto;
}
h1 {
  font-weight: $regular-semibold-weight;
  font-size: 27px;
  color: $juvo-gray-blue;
  letter-spacing: 0;
}
h2 {
  font-weight: $regular-bold-weight;
  font-size: 22px;
  color: $juvo-dark-gray;
}

title {
  font-weight: $regular-semibold-weight;
  font-size: 20px;
  color: $juvo-dark-gray;
  display: block;
}

.categories .category {
  float: left;
  width: auto;
  .input-group-text {
    border-radius: 4px;
  }
}
.file-input {
  padding: 1em;
  background: lighten($juvo-almost-white, 1);
  border: 1px solid darken($juvo-almost-white, 15);
  border-radius: 4px;
}
.save-container {
  padding: 1em;
  background: lighten($juvo-almost-white, 1);
  border: 1px solid darken($juvo-almost-white, 15);
  border-radius: 4px;
}
.google-play {
  width: 200px;
}
.apple-app {
  width: 150px;
}
@media only screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

.universal-link {
  @extend .rounded;
  @extend .border;
  @extend .px-2;
  @extend .py-1;
  display: inline-block;
}

.dashboard-home {

  .card-list {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    height: 100%;
    padding: -0.5rem;
  }

  .card {
    margin: 0.5rem;
    width: 25%;
    a {
      //color: black;
      text-decoration: none;
      i.fa-solid {
        margin-right: 0.5rem;
      }
    }
  }
}

.horizontal-button-list {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  > * {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
}

.collection-items-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $modal-backdrop-bg;
  opacity: $modal-backdrop-opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-border {
    opacity: 1;
  }
}

.alerts-container {
  position: relative;
  .floating-alert {
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    z-index: 10;
  }
}

table.item-collection {
  td.actions > * {
    margin: 0 0.25rem 0 0.25rem;
  }
}

.collection-item-icon {
  font-size: 1.2rem;
}

.form-enabler-label {
  padding-top: add($input-padding-y, $input-border-width);
  padding-bottom: add($input-padding-y, $input-border-width);
  label {
    margin-left: 0.5rem;
  }
}