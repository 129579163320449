.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
}
.autocomplete-items {
  position: absolute;
  max-height: 300px;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  left: 0;
  right: 0;
  overflow-y: auto;
}
.autocomplete-items.above {
  bottom: 100%;
}
.autocomplete-items.below {
  top: 100%;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}
.autocomplete-items.above div {
  border-top: 1px solid #d4d4d4;
}
.autocomplete-items.below div {
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
  background-color: #e9e9e9;
  cursor: hand;
}
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}