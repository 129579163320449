.checkbox-group {
  @extend .rounded;
  @extend .border;
  @extend .px-3;
  @extend .py-2;
}
.checkbox-group-sm {
  @extend .rounded;
  @extend .border;
  @extend .px-3;
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
}
.field-group {
  @extend .rounded;
  @extend .border;
  @extend .px-3;
  @extend .py-2;
}
.inline-field-group {
  @extend .rounded;
  @extend .border;
  @extend .px-3;
  @extend .py-2;
  flex-direction: row;
}
.form-inline > .form-group {
  label {
    @extend .me-1;
  }
}
.bg-brand {
  background-color: $juvo-blue;
}
.bg-light-gray {
  background-color: $juvo-light-gray;
}
.form-check-label-sm {
  font-size: $font-size-sm;
}

.modal-dialog-full-height {
  .modal-content {
    height: 100%;
  }
}

.modal-fullscreen {
  padding: 20px;
}

.modal-fullscreen {
  .modal-body {
    overflow-y: hidden !important;
  }
  .modal-content {
    @include border-radius(var(--#{$prefix}modal-border-radius));
    @include box-shadow(var(--#{$prefix}modal-box-shadow));
  }
} 

.modal-fullscreen.modal-dialog-scrollable {
  .modal-body {
    overflow-y: scroll !important;
  }
} 

.dropdown.scrollable {
  .dropdown-menu {
    overflow: hidden;
    overflow-y: auto;
    max-height: 500px;
  }
}

.form-check-inline-horizontal {
  margin-bottom: 0px !important;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
}

.copy-success-tooltip {
  .tooltip-arrow {
    display: none !important;
  }
  .tooltip-inner {
    background-color: $success;
  }
}