$regular-font: "Open Sans", sans-serif;

$regular-small-font-size: 13px;
$regular-medium-font-size: 16px;
$regular-large-font-size: 25px;

$regular-semibold-weight: 600;
$regular-bold-weight: 700;
$regular-extrabold-weight: 800;

$juvo-blue: #1d82c5; //4A90E2
$juvo-almost-white: #f7f7f7;
$juvo-light-gray: #aab2c0;
$juvo-medium-gray: #d8d8d8;
$juvo-dark-gray: #4a4a4a;
$juvo-gray-blue: #37394c;
$juvo-dark-blue: #146e8a;
$juvo-border-color: #d8d8d8;

@mixin p-fix {
  margin-bottom: 0;
}
