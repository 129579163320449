.candidates-index {
  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .form-group {
      display: flex;
      align-items: center;
    }

    label {
      margin-right: 12px;
      margin-left: 30px;
      margin-bottom: 0px;
    }
  }
}


.candidates-table {

  .candidates-filter {

    button {
      cursor: pointer;
      background:transparent;
      height: 40px;
      font-family: $regular-font;
      color: $juvo-blue;
      margin-bottom: -1px;
      font-weight: $regular-semibold-weight;
      font-size: $regular-medium-font-size;
      @include media-breakpoint-up(sm) {
        border:1px solid transparent;
        width: 160px;
        height: 50px;
      }
      &.rejected{margin-bottom: -1px;&.active {margin-bottom: 0px;}}
      &.active {
        background:transparentize($juvo-light-gray,.70);
        border: 1px solid $juvo-light-gray;
        border-bottom: none;
      }
      &:hover{
        background:transparentize($juvo-light-gray,.90);
        //border: 1px solid $juvo-light-gray;
      }

      .count {
        background-color: $juvo-blue;
        color: white;
        font-size: 14px;
        padding: 0px 4px;
        border-radius: 2px;
        margin-left:6px;
      }

    }
  }

  .candidates-list {
    p {
      margin-bottom: 0;
    }

    .list-header {
      border-top: 1px solid $juvo-light-gray;
      font-weight: $regular-semibold-weight;
      font-size: 12px;
      color: $juvo-light-gray;

      p {
        margin: 13px 0px;
      }
    }

    .candidate {
      align-items: center;
      border-top: 1px solid $juvo-light-gray;

      .name-info {
        display: flex;
        align-items: center;

        .video-icon{
          color: $juvo-blue;
          margin-left:12px;
          font-size:20px;

        }

        .avatar {
          
          img{
            border-radius: 25px;
          }
          max-width:50px;

          @include media-breakpoint-up(sm) {
            margin-left: 1.5rem;
          }
        }

        .name {
          margin-left: 18px;
        }

        .video {
          display: none;

          @include media-breakpoint-up(sm) {
            display: block;
            margin-left: 30px;
          }
        }
      }

      .listing-info {
        display: flex;
        flex-direction: column;
        background-color: $juvo-almost-white;
        border-left: 1px solid $juvo-medium-gray;
        border-right: 1px solid $juvo-medium-gray;
        min-height: 70px;

        @include media-breakpoint-up(sm) {
          padding-top: 12px;
          padding-bottom: 12px;
        }

        .job {
          display: flex;
          flex-direction: row;
          margin-bottom: 12px;
          justify-content: space-between;

          @include media-breakpoint-up(sm) {
            margin-bottom: 0;
          }
          p{
            flex:33%;
          }

          .title {
            font-size: $regular-medium-font-size;
            margin-top: 7px;
            color: $juvo-dark-gray;

            @include media-breakpoint-up(sm) {
              margin-top: 0;
            }
          }

          .name {
            font-size: 12px;
            color: $juvo-dark-gray;
            line-height: 22px;
            margin-top: 7px;
            text-transform: uppercase;

            @include media-breakpoint-up(sm) {
              margin-top: 0;
            }
          }

          .duration {
            display: none;
            font-size: 12px;
            color: $juvo-dark-gray;
            line-height: 22px;
            margin-top: 7px;

            @include media-breakpoint-up(sm) {
              margin-top: 0;
              display: block;
            }
          }
        }
      }
    }
  }
}