@import "choices.js/public/assets/styles/choices.min.css";

.choices__list--dropdown, .choices__list[aria-expanded] {
  z-index: 10 !important;
}
.choices__list--multiple .choices__item {
  background-color: $juvo-blue !important;
  border-color: $juvo-dark-blue !important;
}
.choices__inner {
  @include border-radius($input-border-radius, 0);
  padding: $input-padding-y $input-padding-x;
  min-height: auto;
}
.choices[data-type=select-multiple] {
  .choices__inner {
    @include border-radius($input-border-radius, 0);
    padding: 0.1rem;
  }  
}
.choices__list {
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  padding: 0;
}
.choices__input {
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  margin-bottom: 0px;
}
.choices__button {
  margin-bottom: 0 !important;
}
.choices__list--multiple .choices__item {
  margin: 0.1rem;
}
.choices__input {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}